import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import React, { useState } from "react"
import { Container } from "../components/Global/Container"
import { Hero } from "../components/Index/Hero"
import { ScrollContext } from "./../components/Global/ScrollContext"
import { BackgroundFade } from "./../components/Index/BackgroundFade"
import { Layout } from "./Layout"

const PageLayout = ({ children, pageContext }) => {
  const [scrollY, setScrollY] = useState(0)

  useScrollPosition(({ currPos }) => {
    setScrollY(currPos.y * -1)
  })

  return (
    <ScrollContext.Provider value={{ scrollY }}>
      <Layout pageContext={pageContext}>
        <Hero />
        <Container>{children}</Container>
      </Layout>
      <BackgroundFade />
    </ScrollContext.Provider>
  )
}

export default PageLayout
