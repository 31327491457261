import { useWindowHeight } from "@react-hook/window-size"
import React, { useContext, useState } from "react"
import { animated, useSpring } from "react-spring"
import { ScrollContext } from "./../Global/ScrollContext"

export const BackgroundFade = ({ children }) => {
  const { scrollY } = useContext(ScrollContext)
  const windowHeight = useWindowHeight()

  const [{ springscrollY }, springsetScrollY] = useSpring(() => ({
    springscrollY: 0,
    config: {
      mass: 0.1,
    },
  }))

  // TODO: make this work better 🤔
  springsetScrollY({ springscrollY: scrollY })
  const [bgColor] = useState(() =>
    springscrollY.interpolate({
      range: [0, windowHeight / 2], // must be in pixels I guess 🤔
      output: ["rgba(40, 40, 41, 1)", "#rgba(239, 236, 234, 0)"],
    })
  )

  return (
    <animated.div
      style={{
        position: "fixed",
        zIndex: 0,
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: bgColor,
        pointerEvents: "none",
      }}
    />
  )
}
