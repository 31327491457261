import React from "react"
import styled from "styled-components"
import { Container } from "../Global/Container"
import { Logo } from "./../Global/Logo"

const Em = styled.em`
  font-weight: normal;
  font-style: normal;
  font-family: mono45-headline, monospace;
  letter-spacing: 0.0625em;
`

const Span = styled.span`
  margin: 2rem auto;
  display: inline-block;

  max-width: 37ch;
  font-size: 1.875rem;
  line-height: 1.375;
  margin-top: 6rem;
  @media screen and (max-height: 915px) {
    margin-top: 4rem;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.md}px) {
    margin-top: 2rem;
    font-size: 1.25rem;
  }
`

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`

const Content = styled.div`
  margin-top: -6rem;
  text-align: center;
  width: 100%;
`

const LogoWrap = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 80%;
  height: 100%;
  @media screen and (max-height: 915px) {
    height: 40vh;
    > svg {
      height: 100%;
      width: auto;
    }
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.sm}px) {
    margin-top: 4rem;
    max-width: initial;
  }
`

const StyledSection = styled.section`
  min-height: 100vh;
  color: ${props => props.theme.colors.white};
`

export const Hero = props => {
  return (
    <StyledSection {...props}>
      <Container>
        <Flex>
          <Content>
            <LogoWrap>
              <Logo />
            </LogoWrap>

            <Span className="balance-text">
              Hello! I am a digital designer obsessed with creating delightful
              user experiences using <Em>motion</Em> and{" "}
              <Em>front end development</Em>.
            </Span>
          </Content>
        </Flex>
      </Container>
    </StyledSection>
  )
}
